import React from "react";
import Head from "next/head";
import { GetServerSideProps } from "next";
import KitaBayernHome from "./domains/kita-bayern/KitaBayernHome";
import Lernraum from "./domains/lernraum/Lernraum";
import { useLogin } from "../components/NavBar/NavBar";
import { Loader } from "../components/Loader/Loader";
import Script from "next/script";
import { useRouter } from "next/router";
import { getPixelProgram } from "./api/pixelwerkstatt-program";
import { IStreamConfig } from "../components/common/StreamDisruptor/StreamDisruptor";
import { getStreamConfig } from "./api/pixelwerkstatt-stream-config";
import { isDevEnvironment, isDomain } from "../utils/common";
import Medienecke from "./domains/medienecke/Medienecke";
import {
  Collection,
  getEditorialCollections,
  getPublicCollections,
} from "./api/edu-sharing/collections";
import { Domain } from "../types/common";

function IndexPage({
  host,
  hasAuthCookies,
  collagen,
  streamConfig,
  isDev,
  pixelProgram,
  editorialCollections,
  publicCollections,
}: {
  host: string;
  hasAuthCookies: boolean;
  collagen: string[];
  streamConfig: IStreamConfig;
  isDev: boolean;
  pixelProgram: PixelProgramSerializable[];
  editorialCollections: Collection[];
  publicCollections: Collection[];
}) {
  const router = useRouter();
  const query = (router.query.site || "") as string;
  const { initialized } = useLogin();

  let Page: JSX.Element;
  let title: string;

  switch (true) {
    case isDomain(Domain.PIXELWERKSTATT, host, query):
      title = "PIXELWERKSTATT";
      Page = (
        <Lernraum
          isDev={isDev}
          streamConfig={streamConfig}
          pixelProgram={pixelProgram}
        />
      );
      break;

    case isDomain(Domain.MEDIENECKE, host, query):
      title = "MEDIENECKE";
      Page = (
        <Medienecke
          isDev={isDev}
          editorialCollections={editorialCollections}
          publicCollections={publicCollections}
        />
      );
      break;

    default:
      title = "KITA HUB";
      Page = (
        <KitaBayernHome
          isDev={isDev}
          streamConfig={streamConfig}
          collagen={collagen}
        />
      );
  }

  return (
    <div>
      <Head>
        <title>{title}</title>
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#7ab829" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content={title} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link
          rel="manifest"
          href="/site.webmanifest"
          crossOrigin="use-credentials"
        />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      </Head>
      {initialized || !hasAuthCookies ? Page : <Loader />}

      {router.query.mode !== "APP" &&
        !isDomain(Domain.MEDIENECKE, host, query) && (
          <Script
            id="rocket-script"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
          (function (w, d, s, u) {
            w.RocketChat = function (c) {
              w.RocketChat._.push(c);
            };
            w.RocketChat._ = [];
            w.RocketChat.url = u;
            var h = d.getElementsByTagName(s)[0],
              j = d.createElement(s);
            j.async = true;
            j.src =
              "https://www.chat-alt.kita.bayern/livechat/rocketchat-livechat.min.js?_=201903270000";
            j.onload = function () {
              if (window.RocketChat && window.RocketChat.livechat) {
                window.RocketChat.livechat.setLanguage("de");
              }
            };
            h.parentNode.insertBefore(j, h);
          })(window, document, "script", "https://www.chat-alt.kita.bayern/livechat");
          `,
            }}
          />
        )}
    </div>
  );
}

export const getServerSideProps: GetServerSideProps<{
  streamConfig: IStreamConfig;
  pixelProgram: PixelProgramSerializable[];
  editorialCollections: Collection[];
  publicCollections: Collection[];
}> = async (context) => {
  const host = context?.req?.headers?.host || "";
  const query = (context?.query?.site || "") as string;
  const isDev = isDevEnvironment(host);
  const [streamConfig, pixelProgram, editorialCollections, publicCollections] =
    await Promise.all([
      getStreamConfig(isDev, host, query),
      getPixelProgram(isDev),
      getEditorialCollections(isDev),
      getPublicCollections(isDev),
    ]);

  return {
    props: {
      streamConfig,
      pixelProgram,
      editorialCollections,
      publicCollections,
    },
  };
};

export default IndexPage;
